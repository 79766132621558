<template>
<v-dialog v-model="showingUserModal" width="700" scrollable persistent minHeight="1200">
    <v-form ref="userForm" autocomplete="off" @submit.prevent="createUser">
        <v-card :loading="loading">
            <v-card-title class="text-h5 grey lighten-2">
              {{ editFormId? 'Editar Usuario' : 'Crear Usuario' }}
            </v-card-title>
            <v-card-text style="max-height: 500px">
                <v-row class="mt-2">

                    <v-col cols="6" md="6"> <!-- v-if="!editFormId" -->  
                      <v-text-field
                          ref="name"
                          v-model="user_form.data.name"
                          outlined
                          dense
                          autocomplete="off"
                          hide-details="auto"
                          label="Nombre Completo"
                        />
                          <!-- :rules="[() => !!user_form.data.name || 'Campo requerido']" -->
                    </v-col>

                    <v-col cols="6" md="6" >  
                      <v-text-field
                          ref="address"
                          v-model="user_form.data.address"
                          outlined
                          dense
                          autocomplete="off"
                          hide-details="auto"
                          label="Dirección"
                        />
                          <!-- :rules="[() => !!user_form.data.address || 'Campo requerido']" -->
                    </v-col>

                    <v-col cols="6" md="6">  
                      <v-text-field
                          ref="phone"
                          v-model="user_form.data.phone"
                          outlined
                          dense
                          autocomplete="off"
                          hide-details="auto"
                          label="Teléfono"
                        />
                          <!-- :rules="[() => !!user_form.data.phone || 'Campo requerido']" -->
                    </v-col>

                    <v-col cols="6" md="6" >  
                      <v-text-field
                          ref="cuit"
                          v-model="user_form.data.cuit"
                          outlined
                          dense
                          autocomplete="off"
                          hide-details="auto"
                          label="Cuit"
                        />
                          <!-- :rules="[() => !!user_form.data.cuit || 'Campo requerido']" -->
                    </v-col>

                    <v-col cols="6" md="6"> 
                      <v-text-field 
                        ref="email"
                        v-model="user_form.user.email"
                        outlined
                        dense
                        autocomplete="off"
                        hide-details="auto"
                        validate-on-blur
                        :rules="[rules.required, rules.email]"
                        label="Email"
                      />
                    </v-col >
                    
                    <v-col cols="6" md="6" >  
                      <v-select
                      ref="payment_method"
                        v-model="user_form.data.payment_method"
                        :items="paymentMethods"
                        item-text="name"
                        item-value="id"
                        label="Método de pago"
                        :rules="[v => (!!v || v === 0) || 'Elija una opción']"
                        outlined
                        dense
                      ></v-select>
                    </v-col>

                    <v-col cols="6" class="pt-0" v-if="!editFormId">
                      <v-card-text class="pa-0 pb-1 ma0">Desea enviar email de confirmación al usuario?</v-card-text>
                      <v-switch 
                        class="pa-0 mt-n1 ma0"
                        dense
                        v-model="user_form.user.want_email"
                        :label="`${user_form.user.want_email ? 
                          'Enviar' : 'No enviar'
                          }`"
                      ></v-switch>
                    </v-col>

                       <!-- // EP get UserVet -->
                       <v-col cols="6" class="pt-0" v-if="user_form.data.role === 0">
                      <template >
                        <v-autocomplete
                          v-model="user_form.data.veterinary_clinic"
                          append-icon=""
                          :prepend-inner-icon="icons.mdiMagnify"
                          :items="userVetData"
                          :search-input.sync="searchVet"
                          outlined
                          chips
                          deletable-chips
                          small-chips
                          hide-details
                          label="Seleccionar veterinaria/s"
                          item-text="name"
                          item-value="id"
                          multiple
                          dense
                        >
                        <template #item="data"> 
                            <v-list-item-content> <v-list-item-title>{{ data.item.name }}</v-list-item-title> </v-list-item-content>
                        </template>

                        <v-list-item 
                          v-show="page_size < vetCount"
                          slot="append-item"
                          class="custom-item"
                        >
                          <span class="font-italic text-body-2"> Busca para más elementos <v-icon small> {{icons.mdiMagnify}} </v-icon> </span>
                        </v-list-item>

                        <template v-slot:no-data>
                          <v-list-item> <v-list-item-title> No se encontraron <strong>Veterinarias</strong> </v-list-item-title> </v-list-item>
                        </template>

                        </v-autocomplete>
                      </template>
                    </v-col>

                    <v-col cols="6" class="pt-0">
                      <v-card-text class="pa-0 pb-1 ma0">Simulador de costos</v-card-text>
                      <v-switch 
                        class="pa-0 mt-n1 ma0"
                        dense
                        v-model="user_form.data.simulator"
                        :label="`${user_form.data.simulator ? 
                          'Mostrar' : 'No mostrar'
                          }`"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  
                  <v-row>
                    <v-col cols="6" md="0" > 
                        <v-text-field
                          ref="username"
                          v-model="user_form.user.username"
                          outlined
                          dense
                          autocomplete="off"
                          :rules="[rules.required]"
                          hide-details="auto"
                          label="Nombre de usuario"
                        />
                    </v-col> 

                    <v-col cols="6" md="6" >
                      <v-autocomplete
                      ref="role"
                      v-model="user_form.data.role"
                      :items="this.getUserRole.id === 5 ? roleList : roleList.filter(item => item.value !== 5)"
                      :rules="[() => typeof user_form.data.role === 'number' || 'Campo requerido']"
                      label="Rol"
                      outlined
                      dense
                      placeholder="Selecciona..."
                      required
                      ></v-autocomplete>
                      <!-- :rules="[() => !!user_form.data.role || 'Campo requerido']" -->
                    </v-col> 

                    <v-col class="pt-0" cols="6" md="6" v-if="!editFormId" > 
                      <v-text-field
                          ref="password"
                          v-model="user_form.user.password"
                          :append-icon="showPassword ? icons.mdiEye : icons.mdiEyeOff"
                          :rules="[rules.minLength, rules.required]"
                          validate-on-blur
                          :type="!showPassword ? 'password' : 'text'"
                          @click:append="showPassword = !showPassword"
                          name="input-10-1"
                          outlined
                          dense
                          autocomplete="off"
                          hide-details="auto"
                          label="Contraseña"
                        />
                    </v-col>

                    <v-col class="ms-auto pt-0" cols="6" md="6" v-if="!editFormId" > 
                      <v-text-field
                          ref="confirm_password"
                          v-model="user_form.user.confirm_password"
                          :append-icon="showPassword ? icons.mdiEye : icons.mdiEyeOff"
                          :rules="[rules.minLength, rules.required]"
                          validate-on-blur
                          :type="!showPassword ? 'password' : 'text'"
                          @click:append="showPassword = !showPassword"
                          name="input-10-1"
                          outlined
                          dense
                          autocomplete="off"
                          hide-details="auto"
                          label="Repetir contraseña"
                        />
                    </v-col>
                  </v-row>
                
            </v-card-text>

            <!-- error message -->
            <v-col class="pt-0" align="left" v-if="errorMessage" v-for=" msg in errorMessage">
              <span class="caption" style="color:red;">
                {{ msg }}
              </span>
            </v-col>

            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" :disabled="loading" text @click="close()">
                  CANCELAR
              </v-btn>
              <v-btn color="primary" :disabled="loading" type="submit">
                {{ editFormId? 'EDITAR USUARIO' : 'CREAR USUARIO' }}
              </v-btn>
            </v-card-actions>
            
        </v-card>


    </v-form>
</v-dialog>
</template>

<script>
import debounce from "../../plugins/debounce.js";
import { 
  mdiMagnify,
  mdiClockTimeFourOutline,
  mdiClose,
  mdiEye,
  mdiEyeOff
} from '@mdi/js'

export default {
  data: () => {
    return {
      page: 1,
      page_size: 10,
      searchVet: '',
      vetCount: 0,
      loading: false,
      user_form: {
        user: {
          username: "",
          password: "",
          confirm_password: "",
          email: "",
          want_email: false,
        },
        data: {
          name: "",
          role: null,
          address: "",
          phone: "",
          cuit: "",
          payment_method: 0,
          has_veterinary_clinic: false,
          veterinary_clinic: [],
          simulator: false
        }
      },
      showingUserModal: false,
      userVetData: [],
      editFormId: null,
      roleList: [
        { text: "Super Administrador", value: 5 },
        { text: "Administrador", value: 1 }, 
        { text: "Veterinario", value: 0 },
      ],
      showPassword: false,
      errorMessage: null,
      rules: {
        required: value => !!value || 'Campo requerido',
        minLength: value => value && value.length  >= 8 || 'Ingrese un mínimo de 8 carácteres',
        email: value => {
          const pattern = /^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) ||  'Ingrese un mail valido';
        },
      },
    };
  },
  mounted() {
    this.getVeterinaryList();
  },
  methods: {
    handleVetSearch: debounce(function () {
      this.page = 1;
      this.getVeterinaryList();
    }, 400),
    parseSwitch() {
      this.user_form.user.want_email ? "Registrar" : "No, gracias";
    },
    openModal(id_to_edit) {
      
      id_to_edit
        ? this.setItemToEdit(id_to_edit)
        : this.showingUserModal = true;
    },
    async getVeterinaryList() {
      try {
        const data = {
          page_size: this.page_size,
          page: this.page,
          summary: true,
          search: this.searchVet,
        };
        const response = await this.$api.getVeterinaryList(data);
        this.userVetData = response.results;
        this.vetCount = response.count
      }
      catch (error) {
        console.log(error);
      }
    },
    async setItemToEdit(id_to_edit) {
      const item = await this.$api.getUser(id_to_edit);
      
      if (item) {

        this.editFormId = id_to_edit;
        this.user_form.data.user = item.data.user ?? null;
        this.user_form.user.username = item.data.username ?? null;
        this.user_form.user.email = item.data.email ?? null;
        this.user_form.data.name = item.data.name ?? null;
        this.user_form.data.role = item.data.role.id ?? null;
        this.user_form.data.phone = item.data.phone ?? null;
        this.user_form.data.address = item.data.address ?? null;
        this.user_form.data.cuit = item.data.cuit ?? null;
        this.user_form.data.simulator = item.data.simulator ?? false;
        this.user_form.data.payment_method = item.data.payment_method.id ?? 0;
        this.user_form.data.veterinary_clinic = item.data.veterinary_clinics ?? [];

        this.showingUserModal = true;
      }
    },
    async createUser() {
      if (this.$refs.userForm.validate()) {
        if (this.user_form.data.veterinary_clinic.length) {
          this.user_form.data.has_veterinary_clinic = true
        } 
        
        const form = this.parseForm();
        let editForm = JSON.parse(JSON.stringify(form.data))

        delete editForm.user
        editForm.veterinary_clinic = editForm.veterinary_clinic.map(el => el.id ? el.id : el)

        try {
          this.loading = true
          console.log(form)
          const response = this.editFormId
            ? await this.$api.updateUser(this.editFormId, {...editForm, email: form.user.email, username: form.user.username})
            : await this.$api.createUser(form);
          
          this.$emit('display-alert', {message: this.editFormId ? 'Usuario editado correctamente' : 'Se ha creado un nuevo Usuario', type: 'success'});
          this.$emit("reload-user-list");
          this.close();
          
        }
        
        catch (err) {
          console.log(err.response);
          let errorMessages = [];

          if (err?.response?.data?.username) {
            errorMessages.push(err.response.data.username[0]);
          } 

          if (err?.response?.data?.non_field_errors) {
            errorMessages.push(err.response.data.non_field_errors[0]);
          } 

          if (err?.response?.data?.email) {
            errorMessages.push(err.response.data.email[0]);
          }

          if (errorMessages.length > 0) {
            this.errorMessage = errorMessages
          } else {
            this.errorMessage = ['Ha ocurrido un error, intente nuevamente más tarde'];
          }
        } finally {
          this.loading = false
        }
      }
    },
    close() {
      this.resetForm();
      this.showingUserModal = false;
    },
    resetForm() {
      this.$refs.userForm.reset();
      this.user_form.data.simulator = false
      this.editFormId = null;
      this.errorMessage = null;
      this.user_form.user.want_email = false;
    },
    parseForm() {
      const formParsed = JSON.parse(JSON.stringify(this.user_form));
      return formParsed;
    },
    parseFormToEdit() {
      const form = {
        name: this.user_form.data.name,
        address: this.user_form.data.address,
        phone: this.user_form.data.phone,
        email: this.user_form.user.email,
        cuit: this.user_form.data.cuit,
        simulator: this.user_form.data.simulator,
        payment_method: this.user_form.data.payment_method,
        has_veterinary_clinic: this.user_form.data.has_veterinary_clinic,
        veterinary_clinic: this.user_form.data.veterinary_clinic.map(el => el.id)
      } 

        return form;
    },
  },
  setup() {
    const paymentMethods = [
      {id: 0, name:"Por solicitud"},
      {id:1, name: "Semanal"},
      {id:2, name:"Quincenal"},
      {id:3, name:"Mensual"}
    ]
    return {
      paymentMethods,
      icons: {
        mdiMagnify,
        mdiClose,
        mdiClockTimeFourOutline,
        mdiEye,
        mdiEyeOff
      },
    };
  },
  computed: {
      getUserRole() { return this.$store.getters["session/getUserRole"](); },
  },
  watch: {
    searchVet(val) {
      this.handleVetSearch(val);
    }
  },
  components: {}
}
</script>
<style scoped>
.custom-item {
  background-color: rgba(151, 76, 232, 0.1) !important;
}
</style>

